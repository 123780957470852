<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <conciliacoes-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getConciliacoes()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConciliacoesService from '@/services/ConciliacoesService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    ConciliacoesDataTable: () =>
      import('@/components/power-ups/conciliacoes/ConciliacoesDataTable.vue')
  },

  data: () => ({
    items: [],
    loading: false,
    arrTour: [
      {
        element: '#btn-nova-conciliacao',
        intro: 'Clicando sobre este botão você poderá criar novas conciliações',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#conciliacoes-historico-search',
        intro:
          'Neste campo você poderá pesquisar por conciliações já cadastradas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-download-conciliacao',
        intro:
          'Clicando sobre este botão você poderá fazer o download em .XLSX do resultado de sua conciliação.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-edit-conciliacao',
        intro: 'Clicando sobre este botão você poderá editar sua conciliação.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-conciliacao',
        intro:
          'Aqui você acessa outras opções para sua conciliação, como ver mais detalhes, executá-la novamente ou deletá-la.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ]
  }),

  mounted() {
    this.refreshData(this.getConciliacoes);
  },

  methods: {
    getConciliacoes() {
      this.items = [];
      this.loading = true;
      ConciliacoesService.getConciliacoes()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>
